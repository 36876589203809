/* Artist Card Styles */
.artistCard {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background: white;
  transition: transform 0.2s ease-in-out;
  overflow: hidden;
  height: 100%;
}

.artworkCollage {
  width: 100%;
  aspect-ratio: 1;
  overflow: hidden;
}

.artworkCollage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.artistInfo {
  display: flex;
  align-items: center;
  padding: 16px;
  gap: 16px;
}

.profileImage {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
}

.profileImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.artistName {
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
  color: #333;
}

/* Carousel Section Style Overrides */
.carouselRoot {
  padding: 0 24px;
}

.carouselBlock {
  padding: 12px;
  height: 100%;
}

/* Subscribe Button Styles */
.subscribeButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  width: 100%;
}

.subscribeButton {
  background-color: rgb(51, 51, 51);
  color: white;
  padding: 1rem 2rem;
  font-size: 1.125rem;
  font-weight: 600;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, transform 0.1s ease-in-out;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.subscribeButton:hover {
  background-color: rgb(75, 75, 75);
  transform: translateY(-1px);
}

.subscribeButton:active {
  transform: translateY(0);
}